import _ from "lodash";
import moment from "moment-mini";
import { SongLink, ArtistLink, ShowLink } from "./ActionLink";

const ContentInfo = ({ content }) => {
  const info = (thing) => {
    if (!thing) {
      return "";
    }
    if (thing.type === "song") {
      return songInfo(thing);
    } else if (thing.type === "show") {
      return showInfo(thing);
    } else if (thing.type === "artist") {
      return artistInfo(thing.songs);
    }
  };

  const artistInfo = (data) => {
    const numberOfHits = data.length;
    const songOne = data[0];
    const allDates = _.uniq(_.map(data, "weeks").join(",").split(",")).sort();
    const firstDate = allDates[0];
    const lastDate = allDates[allDates.length - 1];
    const numberOfShows = allDates.length;
    let songsWithBestRank = _.map(data, (d) => {
      return {
        ...d,
        data: _.filter(d.data, (d) => (d.rank && !d.isSpecial ? true : false)),
      };
    });
    songsWithBestRank = _.map(songsWithBestRank, (d) => {
      return {
        ...d,
        bestRank: _.min(_.map(d.data, "rank"), (o) => parseInt(o)),
      };
    });
    const topRank = _.min(_.map(songsWithBestRank, "bestRank"), (o) =>
      parseInt(o)
    );
    let topSongs = _.filter(songsWithBestRank, { bestRank: topRank });
    topSongs = _.sortBy(topSongs, (o) => parseInt(o.data[0]?.x));
    const topSongObj = _.find(topSongs[0]?.data, { rank: topRank?.toString() });
    const topSongDate = topSongObj?.x;
    return (
      <p>
        <ArtistLink song={songOne} /> had {numberOfHits} song
        {numberOfHits > 1 ? "s" : ""} on the show from{" "}
        <ShowLink showDate={firstDate} /> to <ShowLink showDate={lastDate} />.{" "}
        {numberOfHits > 1
          ? `They were on a total of ${numberOfShows} show${
              numberOfShows > 1 ? "s" : ""
            }.`
          : ""}{" "}
        {!topRank ? (
          ""
        ) : (
          <>
            Their top{" "}
            {topSongs.length > 1 ? (
              <>
                songs were{" "}
                {topSongs.map((s, i) => {
                  return (
                    <>
                      {i === topSongs.length - 1 ? "and " : ""}
                      <SongLink song={s} />
                      {i > topSongs.length - 3 ? " " : ", "}
                    </>
                  );
                })}{" "}
                that {topSongs.length > 2 ? "all" : "both"}
              </>
            ) : (
              <>
                song was <SongLink song={topSongs[0]} /> that
              </>
            )}{" "}
            hit #{topRank}
            {topSongs.length === 1 ? (
              <>
                {" "}
                on <ShowLink showDate={topSongDate} />
              </>
            ) : (
              ""
            )}
            .
          </>
        )}
      </p>
    );
  };

  const showInfo = ({ data, info }) => {
    let weekEnding = info.showDate;
    let songsOnChart = _.map(data, (d) => {
      return {
        ...d,
        data: _.filter(d.data, (d) => (d.rank && !d.isSpecial ? true : false)),
      };
    });
    songsOnChart = _.filter(songsOnChart, (d) => {
      return d.data.length > 0;
    });
    let debuts = _.filter(songsOnChart, (song) => {
      return song.data[0].x === weekEnding;
    });
    let finalWeek = _.filter(songsOnChart, (song) => {
      return song.data[song.data.length - 1].x === weekEnding;
    });
    return (
      <div>
        <p>
          {info.showName} hosted by {info.host} aired on{" "}
          {moment(weekEnding).format("MMM Do, YYYY")}.
        </p>
        <div className="row">
          <div className="col-sm">
            <strong>Debuts</strong>
            <ul>
              {debuts.map((s) => {
                let song = _.clone(s);
                song.title = `#${s.data[0].rank} ${s.title} - ${s?.artist}`;
                return (
                  <li key={song.slug}>
                    <SongLink song={song} />
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="col-sm">
            <strong>Final Week on Chart</strong>
            <ul>
              {finalWeek.map((s) => {
                let song = _.clone(s);
                song.title = `#${s.data[s.data.length - 1].rank} ${s.title} - ${
                  s?.artist
                }`;
                return (
                  <li key={song.slug}>
                    <SongLink song={song} />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const songInfo = (song) => {
    if (!song) {
      return "";
    }
    let chartAppearances = _.filter(song.data, (d) => {
      if (d.isSpecial || d.rank === "") {
        return false;
      } else {
        return true;
      }
    });
    let extraAppearances = _.filter(song.data, (d) => {
      return !d.rank && !d.isSpecial;
    });
    let specialAppearances = _.uniqBy(
      _.filter(song.data, (d) => {
        return d.isSpecial;
      }),
      "x"
    );
    let entered,
      enteredRank,
      weeksOnChart,
      finalWeek,
      highest,
      highestWeeks,
      firstWeekAtHighestRank;
    if (chartAppearances && chartAppearances.length > 0) {
      entered = chartAppearances[0].x;
      enteredRank = chartAppearances[0].rank;
      weeksOnChart = chartAppearances.length;
      finalWeek = chartAppearances[chartAppearances.length - 1].x;
      highest = _.min(
        _.map(_.map(chartAppearances, "rank"), (o) => parseInt(o))
      );
      highestWeeks = _.map(
        _.filter(song.data, {
          rank: String(highest),
        }),
        "x"
      );
      firstWeekAtHighestRank = highestWeeks[0];
    }
    return (
      <>
        <p>
          <SongLink song={song} /> by <ArtistLink song={song} />{" "}
          {chartAppearances.length > 0 ? (
            <>
              entered the show on <ShowLink showDate={entered} /> at #
              {enteredRank}. It was on the chart for {weeksOnChart} week
              {weeksOnChart > 1 ? (
                <>
                  s until <ShowLink showDate={finalWeek} />
                </>
              ) : (
                ""
              )}
              . Its highest position on the chart was #{highest} on{" "}
              <ShowLink showDate={firstWeekAtHighestRank} />
              {highestWeeks?.length > 1
                ? ` for ${highestWeeks?.length} weeks`
                : ""}
              .
            </>
          ) : (
            "did not chart."
          )}{" "}
          {extraAppearances.length > 0
            ? `It was an extra on ${extraAppearances.length} show${
                extraAppearances.length > 1 ? "s" : ""
              }.`
            : ""}{" "}
          {specialAppearances.length > 0
            ? `It was on ${specialAppearances.length} special show${
                specialAppearances.length > 1 ? "s" : ""
              }.`
            : ""}
        </p>
        <div className="row">
          <div className="col-12 col-md-3">
            <h5>Shows</h5>
            {chartAppearances.length > 0 ? (
              <ul>
                {" "}
                {chartAppearances.map((a) => {
                  return (
                    <li key={a.id + a.x}>
                      <ShowLink showDate={a.x} /> (#{a.rank})
                    </li>
                  );
                })}
              </ul>
            ) : (
              "None"
            )}
          </div>
          <div className="col-12 col-md-4">
            <h5>Extras</h5>
            {extraAppearances.length > 0 ? (
              <ul>
                {" "}
                {extraAppearances.map((a) => {
                  return (
                    <li key={a.x}>
                      {a.label} <ShowLink showDate={a.x} />
                    </li>
                  );
                })}
              </ul>
            ) : (
              "None"
            )}
          </div>
          <div className="col-12 col-md-5">
            <h5>Specials</h5>
            {specialAppearances.length > 0 ? (
              <ul>
                {" "}
                {specialAppearances.map((a) => {
                  return (
                    <li key={a.x + a.showName}>
                      <ShowLink showDate={a.x} showName={a.showName} />
                    </li>
                  );
                })}
              </ul>
            ) : (
              "None"
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="songInfo">
      <div>{info(content?.selected)}</div>
      <div>
        {content.highlighted?.slug !== content.selected?.slug
          ? songInfo(content.highlighted)
          : ""}
      </div>
    </div>
  );
};

export { ContentInfo };

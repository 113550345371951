import { useState, useEffect } from "react";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { animated } from "@react-spring/web";
import { filter, find, map, max } from "lodash";
import { publish } from "./events";
import moment from "moment-mini";

const ScatterPlot = ({ data, screen, onHighlight, dates }) => {
  let [highlightedSong, setHilightedSong] = useState("");
  let [chartMax, setChartMax] = useState(0);
  let [totalDays, setTotalDays] = useState(0);

  useEffect(() => {
    // Find the song in the data
    let song = find(data, { id: highlightedSong });
    onHighlight(song?.slug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedSong]);

  useEffect(() => {
    let tempChartMax = 0;
    for (var i = 0; i < data.length; i++) {
      let song = data[i];
      let filteredSongData = filter(
        song.data,
        (d) => d.x > dates.min && d.x < dates.max
      );
      let maxRank = max(map(filteredSongData, "songsInShow"));
      if (tempChartMax < maxRank) {
        tempChartMax = maxRank;
      }
    }
    setChartMax(tempChartMax);
    setTotalDays(moment(dates.max).diff(moment(dates.min), "days"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dates]);

  return (
    <ResponsiveScatterPlot
      nodeSize={10}
      useMesh={true}
      data={data}
      margin={{
        top: screen.isMobile ? 20 : 50,
        right: screen.isMobile ? 20 : 250,
        bottom: screen.isMobile ? 20 : 50,
        left: screen.isMobile ? 20 : 60,
      }}
      xScale={{
        min: dates.min,
        max: dates.max,
        type: "time",
        format: "%Y-%m-%d",
        precision: "day",
      }}
      axisBottom={{
        format: "%d %b %y",
      }}
      yScale={{
        type: "linear",
        min: 1,
        max: chartMax ? chartMax : "auto",
        reverse: true,
      }}
      axisLeft={{
        format: (d) => {
          return "";
        },
      }}
      nodeComponent={({ blendMode, style, node }) => {
        let sizeModifier = 2;
        if (node?.serieId === highlightedSong) {
          sizeModifier = 1;
        }
        return (
          <animated.circle
            cx={style.x}
            cy={style.y}
            r={style.size.to((size) => size / sizeModifier)}
            fill={style.color}
            style={{ mixBlendMode: blendMode }}
          />
        );
      }}
      tooltip={(data) => {
        let node = data.node;
        let date = moment(node?.formattedX?.substring(0, 15));
        let daysFromStart = moment(date).diff(moment(dates.min), "days");
        let startPercent = Math.round((daysFromStart / totalDays / 2) * 100);
        let endPercent = 50 - startPercent;
        return (
          <div
            style={{
              width: 480,
            }}
          >
            <div
              style={{
                fontSize: 12,
                padding: 8,
                color: "white",
                marginLeft: endPercent + "%",
                marginRight: startPercent + "%",
                textAlign: "left",
                background: "#222222",
              }}
            >
              {node?.serieId}
              <br />
              {node?.data?.rank ? "#" + node?.data?.rank : node?.data?.label}
              <br />
              {node?.data?.showName}
            </div>
          </div>
        );
      }}
      legends={
        screen.isMobile
          ? []
          : [
              {
                anchor: "top-right",
                direction: "column",
                justify: false,
                translateX: 280,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 250,
                itemHeight: 15,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .1)",
                      itemOpacity: 1,
                      symbolSize: 15,
                      itemHeight: 20,
                      itemTextColor: "black",
                    },
                  },
                ],
                onMouseEnter: ({ id, label }) => {
                  setHilightedSong(id);
                },
                onClick: ({ id }) => {
                  // Find the song in the data
                  let song = find(data, { id });
                  if (song) {
                    publish("linkClicked", `/song/${song.slug}`);
                  }
                },
              },
            ]
      }
    />
  );
};

export { ScatterPlot };

import React, { useEffect } from "react";
import { publish } from "./events";
import moment from "moment-mini";
import slug from "slug";

function SongLink({ song }) {
  return ActionLink({
    href: `/song/${song.slug}`,
    linkText: song.title,
  });
}

function ArtistLink({ song }) {
  let artistSlug = slug(song.artist);
  return ActionLink({
    href: `/artist/${artistSlug}`,
    linkText: song.artist,
  });
}

function ShowLink({ showDate, showName }) {
  if (!showName) {
    showName = moment(showDate).format("MMM Do, YYYY");
  }
  return ActionLink({
    href: `/show/${showDate}`,
    linkText: showName,
  });
}

function ActionLink({ href, linkText }) {
  const [target, setTarget] = React.useState(href);

  useEffect(() => {
    setTarget(href);
  }, [href]);

  const handleClick = (e) => {
    e.preventDefault();
    publish("linkClicked", target);
  };

  return (
    <a href={`${href}`} onClick={handleClick}>
      {`${linkText}`}
    </a>
  );
}

export { ActionLink, ArtistLink, SongLink, ShowLink };
